<template>
  <div id="js-loader" class="loader">
    <div class="loader-animation"></div>
  </div>
  <div class="maincontent" id="content" style="min-height: 605px">
    <div
      class="maincontentinner"
      style="opacity: 1; left: 0px; transition: all 0.5s ease 0s"
    >
      <div class="mt_wrap">
        <h1 class="ttl_1">ダッシュボード<span></span></h1>
        <nav class="breadcrumb_wrap">
          <ul class="breadcrumb">
            <li>
              <router-link :to="{name: 'Dashboard'}"> ダッシュボード </router-link>
            </li>
          </ul>
        </nav>
      </div>
      <!-- <SuccessMessage v-if="successMessage" /> -->

      <div class="command_wrap command_bottom">
        <div class="leftarea btn_wrap">
          <div class="return_link">
          </div>
          <div></div>
        </div>
        <!--leftarea-->
        <div class="rightarea">
          <!--commandtext_wrap-->
        </div>
        <!--rightarea-->
      </div>
      <!--command_wrap-->
      <!-- <Spinner v-if="spinner" /> -->

      <div class="contentout_wrap">
        <div class="content_wrap twocolumn">
          <div class="box_wrap left mih_500px">
            <h2 class="ttl_2">Webサイトの公開状況</h2>
            <div class="scr_outwrap">
                <div class="scr_wrap">
                    <table class="formtable">
                        <tr>
                            <th class="wid_30per">
                                社長メッセージ
                            </th>
                            <td>
                                <div class="form_outwrap dashboard_status">
                                    <div class="wrap m_b0">
                                        <div class="in_wrap">
                                            <span v-if="page_status.ceo_status != '公開'" class="nopublic"><i class="fas fa-lock"></i>非公開中</span>
                                            <span v-else-if="page_status.ceo_status == '公開'" class="public"><i class="fas fa-check-circle"></i>公開中</span>

                                        </div>
                                    </div>
                                    <div class="wrap m_b0">
                                        <div class="in_wrap">
                                            <p class="commandtext"><router-link :to="{name: 'Page Show',params: { type:'ceo' },}"><i class="fas fa-chevron-right m_r10 fs_80per"></i>社長メッセージ詳細</router-link></p>
                                        </div>
                                    </div>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <th class="wid_30per">
                                社是
                            </th>
                            <td>
                                <div class="form_outwrap dashboard_status">
                                    <div class="wrap m_b0">
                                        <div class="in_wrap">
                                            <span v-if="page_status.business_status != '公開'" class="nopublic"><i class="fas fa-lock"></i>非公開中</span>
                                            <span v-else-if="page_status.business_status == '公開'" class="public"><i class="fas fa-check-circle"></i>公開中</span>

                                        </div>
                                    </div>
                                    <div class="wrap m_b0">
                                        <div class="in_wrap">
                                            <p class="commandtext"><router-link :to="{name: 'Page Show',params: { type:'business' },}"><i class="fas fa-chevron-right m_r10 fs_80per"></i>社是詳細</router-link></p>
                                        </div>
                                    </div>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <th class="wid_30per">
                                会社・部門方針
                            </th>
                            <td>
                                <div class="form_outwrap dashboard_status">
                                    <div class="wrap m_b0">
                                        <div class="in_wrap">
                                            <span v-if="page_status.policy_status != '公開'" class="nopublic"><i class="fas fa-lock"></i>非公開中</span>
                                            <span v-else-if="page_status.policy_status == '公開'" class="public"><i class="fas fa-check-circle"></i>公開中</span>

                                        </div>
                                    </div>
                                    <div class="wrap m_b0">
                                        <div class="in_wrap">
                                            <p class="commandtext"><router-link :to="{name: 'Page Show',params: { type:'policy' },}"><i class="fas fa-chevron-right m_r10 fs_80per"></i>会社・部門方針詳細</router-link></p>
                                        </div>
                                    </div>
                                </div>
                            </td>
                        </tr>
                    </table><!--formtable-->
                </div><!--scr_wrap-->
            </div><!--scr_outwrap-->
        </div>

          <!--box_wrap-->
        </div>
        <!--content_wrap-->
      </div>
      <!--contentoutwrap-->
    </div>
    <!--maincontentinner-->
  </div>
</template>

<script>
import "@/assets/front_component/js/functions.js";
import $ from 'jquery';
import message from "@/apis/Message";
import user from "@/apis/User";
import AdminPageType from "@/const/AdminPageType.json";
import dayjs from 'dayjs';

export default {
  name: "message",
  data() {
    return {
		page_status:[],
    };
  },
  components: {  },
  beforeCreate(){
    $(function(){   
      // body に open クラスをつけたりはずしたりする( open クラスは空)
      $('.g_header_list').removeClass('open');
      $('#button').removeClass('active');
      $(document.body) .removeClass('open');
      var result = $('body').attr('style');
      var int_data = result.replace(/[^0-9]/g, '');
      $("html,body").css({
        "overflow": "auto",
        "height": "auto",
      });
      //bodyfixedを解除する
      $('body').css({
        'position': '',
        'width': '',
        'top': ''
      });
      $(window).scrollTop(int_data); 
      $('.g_header_list  > li.has_child').removeClass('open');
      $('.g_header_list  > li.has_child').find('.menu_outwrap').slideUp();
    });
    $(function(){
      $('#js-loader').show();
      $(window).scrollTop(0);
    });

  },
  created() {
    this.GetPageStatus('ceo');
    this.GetPageStatus('business');
    this.GetPageStatus('policy');
    console.log(this.page_status);
  },
    mounted() {
            document.body.className = 'page_control';
			this.$nextTick(function() {
				$(function(){
					$('.menu_icon').click(function(){
						if($('.menu_list_wrap').hasClass('show')){
							$('.menu_list_wrap').removeClass('show');
						}else{
							$('.menu_list_wrap').addClass('show');
						}
					});
				});
				$(document).click(function(event) {
					if(!$(event.target).closest('.menu_wrap').length) {
						$('.menu_list_wrap').removeClass('show');
					}
				});
				$(function(){
					var content_height = $("#app").height(); // コンテンツの高さを取得
					var pagetop_show = content_height - 1500; // ページトップを出すの高さを設定
					$(window).on("scroll", function () {
						var scroll = $(window).scrollTop();
						var windowHeight = $(window).height();
						//ページトップ表示
						if(scroll > pagetop_show && scroll>60){
							$('.pagetop_wrap').addClass('show');
						} else {
							$('.pagetop_wrap').removeClass('show');
						}
					});
				});
				$(function(){
					$('#js-loader').delay(300).fadeOut(600);
					$(window).scrollTop(0);
				});
			});
        $('figure.media').each(function() {
              var $y_url = $(this).children().attr('url').replace('https://youtu.be/', '');
              $(this).children().replaceWith("<iframe class='post_youtube' title='YouTube video player' frameborder='0' allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture' allowfullscreen></iframe>");
              $(this).children('.post_youtube').attr("src", "https://www.youtube.com/embed/"+$y_url);
        });
        },
  methods: {
    GetPageStatus(page_type) {
      message.list(page_type)
        .then((response) => {
          if (response.data.data.length > 0) {
            console.log(response);
            var status_name = page_type + '_status';
            this.page_status[status_name] = response.data.data[0].status;
          }
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
        });
    },
  },
};
</script>

<style scoped>
/* 外部CSSスタイルシートを追加する */
@import "../../assets/control_panel/scss/main.scss";
</style>
